import styled from "styled-components"
import { theme } from "shared/styles/theme"
import * as C from "shared/components"

const { breakpoints } = theme

export const Root = styled.header`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  @media (max-width: ${breakpoints.md}px) {
    a:not(:first-child) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
`

export const Wrapper = styled(C.ContentWrapper)`
  height: 6.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
