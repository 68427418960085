import styled from "styled-components"
import { Link } from "gatsby"
import { theme } from "shared/styles/theme"
import CreatorLogoHorizontal from "shared/assets/persona-logo.svg"
import CreatorLogoVertical from "shared/assets/persona-logo-footer.svg"

const { breakpoints } = theme

export const StyledLogoLink = styled(Link)`
  text-decoration: none;
  transition: opacity ${props => props.theme.animation.fast};

  &:hover {
    opacity: 0.75;
  }

  @media (max-width: ${breakpoints.md}px) {
    max-width: ${props => (props.covered ? "10rem;" : "")};
    overflow: ${props => (props.covered ? "hidden;" : "")};
  }
`

export const StyledLogoHorizontal = styled(CreatorLogoHorizontal)`
  width: 100%;
  max-width: 22.1875rem;

  @media (max-width: ${breakpoints.md}px) {
    width: ${props => (props.covered ? "22.1875rem;" : "")};
  }
`

export const StyledLogoVertical = styled(CreatorLogoVertical)`
  width: 100%;
  max-width: 13.8rem;
`
