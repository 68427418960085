import React from "react"

import * as C from "shared/components"

import * as S from "./styled"

const buttonOverrides = {
  paddingLeft: "1.5rem",
  paddingRight: "1.5rem",
  fontWeight: 400,
}

export const Header = () => (
  <S.Root>
    <S.Wrapper>
      <C.Logo covered />
      <C.Button
        id="try-now-button"
        to="/persona"
        size="md"
        fontFamily="ttCommons"
        color="darkGreyBlue"
        overrides={buttonOverrides}
      >
        Try now
      </C.Button>
    </S.Wrapper>
  </S.Root>
)
